
$(function(){
    var makeForm = function(form) {
        var button = form.find('button');
        button.ladda();
        var segAlerta = 10; // Segundos de la alerta
        form.submit(function(e) {
            e.preventDefault();
            $.ajax({
                url: '/api/enviar',
                data: form.serialize(),
                method: 'post',
                dataType: 'json',
                beforeSend: function() {
                    button.ladda('toggle');
                }
            }).done(function(data) {
                if (data.status === "ok") {
                    swal({
                        title: Lang.get('cliente.index.contacto.alerta.success.title'),
                        text: Lang.get('cliente.index.contacto.alerta.success.body'),
                        type: 'success',
                        timer: segAlerta * 1000
                    });
                    form.find("input, textarea").not("input[type=hidden]").val("");
                }
            }).fail(function(err) {
                if (err.error) {
                    swal({
                        title: Lang.get('cliente.index.contacto.alerta.error.title'),
                        text: Lang.get('cliente.index.contacto.alerta.error.body'),
                        type: 'error',
                        timer: segAlerta * 1000
                    });
                }
            }).always(function(){
                button.ladda('toggle');
            });
        })
    };
    if ($('.contact-form').length > 0) makeForm($('.contact-form'));
    if ($('.contact').length > 0) makeForm($('.contact'));

    // Funcion para presupuesto
    var budget = $('#app_budget');
    if (budget.length > 0) {
        $.get('/api/precios', function(data){
            if (data) {
                budget.attr('disabled', false);
                data[0].lista.forEach(function(d){
                    budget.append($('<option>', {
                        value: d,
                        text: d
                    }));
                });
                $('#divisa').change(function(e){
                    data.forEach(function(d){
                        if (d.divisa == e.target.value) {
                            budget.html($('<option>', { text: Lang.get('cliente.index.contacto.sel_presupuesto') }));
                            d.lista.forEach(function(l){
                                budget.append($('<option>', {
                                    value: l,
                                    text: l
                                }));
                            });
                        }
                    })
                })
            }
        })
    }
});
