/**
 * Created by Keyvin on 5/2/2016.
 */
$(function(){
    $.ajaxSetup({
        headers: {
            "X-CSRF-TOKEN": $('meta[name="_token"]').attr('content')
        }
    });
    $('img.img-circle.img-responsive').hover(function(){
        var ele = $(this);
        ele.addClass('animated pulse');
    }, function(){
        var ele = $(this);
        ele.removeClass('animated pulse');
    });
});